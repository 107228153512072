import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

const colours =
     ['AliceBlue','AntiqueWhite','Aqua','Aquamarine','Azure','Beige','Bisque','Black',
    'BlanchedAlmond','Blue','BlueViolet','Brown','BurlyWood','CadetBlue','Chartreuse',
    'Chocolate','Coral','CornflowerBlue','Cornsilk','Crimson','Cyan','DarkBlue','DarkCyan',
    'DarkGoldenRod','DarkGray','DarkGrey','DarkGreen','DarkKhaki','DarkMagenta',
    'DarkOliveGreen','DarkOrange','DarkOrchid','DarkRed','DarkSalmon','DarkSeaGreen',
    'DarkSlateBlue','DarkSlateGray','DarkSlateGrey','DarkTurquoise','DarkViolet','DeepPink',
    'DeepSkyBlue','DimGray','DimGrey','DodgerBlue','FireBrick','FloralWhite','ForestGreen',
    'Fuchsia','Gainsboro','GhostWhite','Gold','GoldenRod','Gray','Grey','Green','GreenYellow',
    'HoneyDew','HotPink','IndianRed','Indigo','Ivory','Khaki','Lavender','LavenderBlush','LawnGreen',
    'LemonChiffon','LightBlue','LightCoral','LightCyan','LightGoldenRodYellow','LightGray',
    'LightGrey','LightGreen','LightPink','LightSalmon','LightSeaGreen','LightSkyBlue',
    'LightSlateGray','LightSlateGrey','LightSteelBlue','LightYellow','Lime','LimeGreen','Linen',
    'Magenta','Maroon','MediumAquaMarine','MediumBlue','MediumOrchid','MediumPurple','MediumSeaGreen',
    'MediumSlateBlue','MediumSpringGreen','MediumTurquoise','MediumVioletRed','MidnightBlue','MintCream',
    'MistyRose','Moccasin','NavajoWhite','Navy','OldLace','Olive','OliveDrab','Orange','OrangeRed',
    'Orchid','PaleGoldenRod','PaleGreen','PaleTurquoise','PaleVioletRed','PapayaWhip','PeachPuff','Peru',
    'Pink','Plum','PowderBlue','Purple','RebeccaPurple','Red','RosyBrown','RoyalBlue','SaddleBrown',
    'Salmon','SandyBrown','SeaGreen','SeaShell','Sienna','Silver','SkyBlue','SlateBlue','SlateGray',
    'SlateGrey','Snow','SpringGreen','SteelBlue','Tan','Teal','Thistle','Tomato','Turquoise','Violet',
    'Wheat','White','WhiteSmoke','Yellow','YellowGreen'];

const TWO_PI = 2.0 * Math.PI;
var rotation_done = false;
var far = 16.0;
function rand_int(m, n) {
    m = Math.ceil(m);
    n = Math.floor(n);
    return Math.floor(Math.random() * (n - m) + m);
}

//Base

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

// Textures
const textureLoader = new THREE.TextureLoader();
const matcap_fo_texture = textureLoader.load('/textures/matcaps/mc_orange.png');
// const matcap_text_texture = textureLoader.load('/textures/matcaps/mc_spiky.png');
const matcap_text_texture = textureLoader.load('/textures/matcaps/2.png');

const cubeTextureLoader = new THREE.CubeTextureLoader();

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/frac00/px_s.png',
    '/textures/environmentMaps/frac00/nx_s.png',
    '/textures/environmentMaps/frac00/py_s.png',
    '/textures/environmentMaps/frac00/ny_s.png',
    '/textures/environmentMaps/frac00/pz_s.png',
    '/textures/environmentMaps/frac00/nz_s.png'
]);

const params = {
    colour: 0xffcc85,
    wireframe: false,
    wireframeLinewidth: 1,
    metalness: 1.0,
    roughness: 0.,
};

// Text / Fonts
const textMaterial = new THREE.MeshMatcapMaterial({matcap: matcap_text_texture});
const font_loader = new FontLoader();
let font_params = {
    font: null,
    size: 1.4,
    height: 0.02,
    curveSegments: 5,
    bevelEnabled: true,
    bevelThickness: 0.05,
    bevelSize: 0.03,
    bevelOffset: 0,
    bevelSegments: 4
};
let [text, textGeometry] = [null, null];
font_loader.load('/fonts/gentilis_bold.typeface.json', font => {
    font_params.font = font;
    const textGeometry = new TextGeometry('Illegal Operation', font_params);
    textGeometry.computeBoundingBox();
    textGeometry.center();
    console.log(textGeometry);
    text = new THREE.Mesh(textGeometry, textMaterial);
    text.scale.set(0.1, 0.1);
    text.position.z = -16.;
    scene.add(text);
});

// Sizes
class Sizes {
    static width = window.innerWidth;
    static height = window.innerHeight;
    static aspect = this.width / this.height;
    static update_renderer(renderer) {
        this.update_sizes();
        renderer.setSize(this.width, this.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    static update_camera(camera) {
        this.update_sizes();
        camera.aspect = this.width / this.height;
        camera.updateProjectionMatrix();
    }
    static update_sizes() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }
    static update(camera, renderer) {
        this.update_camera(camera);
        this.update_renderer(renderer);
    }
}

// Tori
class Torus {
    constructor(
        radius,
        tubeRadius,
        radialSegments=16,
        tubularSegments=100,
        volume=4,
        colour=0xffbb00
    ) {
        this.radius = radius;
        this.tubeRadius = tubeRadius;
        this.radialSegments = radialSegments;
        this.tubularSegments = tubularSegments;
        this.volume = volume;
        this.position = {
            x: this.rand_with_max_magnitude(this.volume),
            y: this.rand_with_max_magnitude(this.volume),
            z: -Math.random() * this.volume
        };
        this.velocity = {
            x: (Math.random() - 0.5) * 0.004,
            y: (Math.random() - 0.5) * 0.004,
            z: (Math.random() - 0.5) * 0.004
        };
        this.rotation = {
            x: this.rand_with_max_magnitude(Math.PI),
            y: this.rand_with_max_magnitude(Math.PI)
        };
        this.angular_velocity = {
            x: Math.random() * 2.,
            y: Math.random() * 2.
        };
        this.colour = colour;
    }

    rand_with_max_magnitude(n) {
        return (Math.random() - 0.5) * 2. * n;
    }

    get mesh() {
        const plain = Math.random() > 0.25;
        let geometry;
        if (plain) {
            geometry = new THREE.TorusGeometry(
                this.radius, 
                this.tubeRadius, 
                this.radialSegments,
                this.tubularSegments
            );
        }
        else {
            const [p, q] = [rand_int(1,6), rand_int(1,6)];
            geometry = new THREE.TorusKnotGeometry(
                this.radius, 
                this.tubeRadius, 
                this.tubularSegments,
                this.radialSegments,
                p,
                q
            );
        }
        const material = new THREE.MeshMatcapMaterial({
            matcap: matcap_fo_texture,
            color: this.colour
        });
        const m = new THREE.Mesh(geometry, material);
        m.position.x = this.position.x;
        m.position.y = this.position.y;
        m.position.z = this.position.z;
        m.rotation.x = this.rotation.x;
        m.rotation.y = this.rotation.y;
        m.wrapper = this;
        return m;       
    }
}

const tori = [];
for (let i=0; i < 512; i++) {
    const t = new Torus(0.2, 0.03, 20, 256, 4, colours[rand_int(0, colours.length)]);
    const m = t.mesh;
    tori.push(m);
    scene.add(m);
}

const material = new THREE.MeshStandardMaterial({
    wireframe: params.wireframe,
    wireframeLinewidth: params.wireframeLinewidth,
    metalness: params.metalness,
    roughness: params.roughness
});
material.color = new THREE.Color(params.colour);
material.envMap = environmentMapTexture;

const icosahedron = new THREE.Mesh(
    new THREE.IcosahedronGeometry(1.0, 0),
    material
);
icosahedron.geometry.setAttribute('uv2', new THREE.BufferAttribute(icosahedron.geometry.attributes.uv.array, 2));

scene.add(icosahedron);

// Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
const pointLight = new THREE.PointLight(0xffffff, 0.5);
console.log(ambientLight, pointLight);
pointLight.position.x = 2;
pointLight.position.y = 3;
pointLight.position.z = 4;

scene.add(ambientLight, pointLight);

window.addEventListener('resize', _ => {
    // Update sizes, camera, renderer when window resized
    Sizes.update(camera, renderer);
});

// Camera
const camera = new THREE.PerspectiveCamera(75, Sizes.width / Sizes.height, 0.1, 100);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 6;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

//Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
});
Sizes.update_renderer(renderer);

// Debug
const gui = new dat.GUI();
const debug_panel = document.querySelector('.lil-gui');
debug_panel.classList.add('hide');
gui
    .add(params, 'wireframe')
    .onChange(_ => {
        textMaterial.wireframe = params.wireframe;
    });
gui
    .add(params, 'wireframeLinewidth')
    .onChange(_ => {
        textMaterial.wireframeLinewidth = params.wireframeLinewidth;
    });
gui
    .add(ambientLight, 'intensity')
    .min(0)
    .max(1)
    .step(0.01);
gui
    .add(pointLight, 'intensity')
    .min(0)
    .max(1)
    .step(0.01);

window.addEventListener('keyup', event => {
    switch(event.key.toLowerCase()) {
        case 'd':   // Show/hide debug GUI
            debug_panel.classList.toggle('hide');
            break;
    }
});

// Animate
const clock = new THREE.Clock();
function render() {
    const elapsedTime = clock.getElapsedTime();
    if (text && !rotation_done) {
        text.rotation.z += 0.06;
        if (text.position.z < 4.7) {
            text.position.z += 0.05;
        }
        else if (text.rotation.z % TWO_PI < 0.1) {
                text.rotation.z = 0;
                rotation_done = true;
        }
    }
    tori.forEach(t => {
        t.rotation.x = t.wrapper.angular_velocity.x * elapsedTime * 0.2;
        t.rotation.y = t.wrapper.angular_velocity.y * elapsedTime * 0.3;

        t.position.x = t.position.x + t.wrapper.velocity.x;
        if (t.position.x >= far) {
            t.position.x -= 32.0;
        }
        if (t.position.x <= -far) {
            t.position.x += 32.0;
        }
        t.position.y = t.position.y + t.wrapper.velocity.y;
        if (t.position.y >= far) {
            t.position.y -= 32.0;
        }
        if (t.position.y <= -far) {
            t.position.y += 32.0;
        }
    });
    icosahedron.rotation.x = 0.3 * elapsedTime;
    icosahedron.rotation.y = 0.075 * elapsedTime;
    icosahedron.position.x = 2.4 * Sizes.aspect * Math.sin(0.4 * elapsedTime - (Math.PI / 2. + 0.8));
    icosahedron.position.y = 2.4 * Math.cos(0.4 * elapsedTime - (Math.PI / 2. + 0.8));
    icosahedron.position.z = 0.2 * Math.cos(0.001 * elapsedTime);
    controls.update();
    renderer.render(scene, camera);
    window.requestAnimationFrame(render);
}

render();